<template>
  <div class="productBody">
    <div v-for="(item,index) in list.list" :key="index">
        <span class="title">{{item.colName}}:</span>
        <span class="conten" v-if="item.colValue.indexOf('|')==-1">{{item.colValue}} </span>
        <ul class="type4" v-else>
          <li v-for="(el,eindex) in item.colValue.split('|')" :key="eindex">
            {{el}}
          </li>
        </ul>
    </div>
    <!-- <div><span class="title">委托类型:</span><span class="conten">{{list.authType=='1'?'企业':'个人'}}</span></div>
    <div v-if="list.authType=='1'"><span class="title">委托企业:</span><span class="conten">{{list.enterprise}}</span></div>
    <div v-if="list.content && list.content !=''">
      <div><span class="content" v-html="list.content"></span></div>
    </div> -->
    <!-- <div v-else>
      <div><span class="title">来样时间:</span><span class="conten">{{list.createDate}}</span></div>
      <div><span class="title">检验时间:</span><span class="conten">{{list.auditDate}}</span></div>
      <div><span class="title">检验机构:</span><span class="conten">{{jsonPrase(list.auditOrg)}}</span></div>
      <div><span class="title">{{getAudit(list.auditType)}}:</span> <span :class="['conten', list.resultType == 1 ? '' : list.resultType == 2?'textColor':'textColor1']">{{list.auditResult}}</span></div>
      <div v-if="list.safeSign==1" class="flexdiv">
        <span><img width="20px" height="20px" style="margin-right:5px;padding-top:5px" src="../assets/bao.png" alt=""></span>
        <span class="title">中华联合财产保险股份有限公司为优检提供职业责任保险保障</span>
        </div>
      <div><span class="title">备注:</span><span class="conten" v-html="replace(list.remark)"></span></div>
    </div> -->
    <div class="bottomBut">
      <van-button type="info" round style="width: 100%;margin-bottom:10px" @click="$router.push({name: 'certificate',params:{data:list.traceList[0]}})">区块链证书</van-button>
      <!-- <div class="btm"> -->
      <van-button v-if="list.codeSum && list.codeSum!=0 " style="width: 100%;background-color:darkorange;color:#fff" round @click="handleCheckJump">防伪查询</van-button>
      <!-- </div> -->
    </div>
    
  </div>
</template>
<script>
export default {
  props:{
    list:{
      type: Object,
      required: true
    }
  },
  data(){
    return{
    }
  },
  methods:{
    replace(e){
      if(e){
        return e.replace(/。/g,"</br>")
      }
    },
    jsonPrase(str){
      // console.log(str);
      if(str){
        if(str.indexOf(']')<0){
          return str
        }else{
          const arr = JSON.parse(str)
          return arr.toString()
        }
      }
    },
    handleCheckJump(){
      let batchId = localStorage.getItem('batchId')
      let isIndex = localStorage.getItem('indexNo')
      this.$router.push({
        name:'check',
        query:{
          batchId,
          noIndex:(isIndex && isIndex!='')?false:true
        }
      })
    },
    getAudit(type){
      if(type=='1'){
        return '检验结论'
      }
      if(type=='2'){
        return '检测结论'
      }
      if(type=='3'){
        return '合格评定结论'
      }
    }
  }
}
</script>
<style scoped>
.productBody{
    padding: 10px 20px;
    line-height: 26px;
    font-size: 0.8rem
}
.textColor{
  color: #ff0000;
}
.textColor1{
  color: #ffba00;
}
.productBody .title{
    color: #000;
    font-weight: 600
}
.productBody .conten{
    margin-left: 10px;
    word-wrap:break-word;
    word-break: break-all;
}
.bottomBut{
  text-align: center;
  margin-top: 20px;
}
.flexdiv{
  display:flex;
  align-items: center;
}
.type4{
  list-style: none;
}
</style>

